import React from "react"
import Index from "@pages/cookiepolicy.js";

const CookiepolicyJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default CookiepolicyJa
